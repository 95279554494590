<template>
    <div class="custom-select-container position-relative">
        <div class="custom-select" @click="toggleDropdown">
            <div class="d-flex flex-column container-title-and-option">
                <span class="label-custom-select">{{ label }}</span>
                <div class="custom-select-value">{{ selectedOption?.value || '' }}</div>
            </div>
            <div class="custom-select-icon">
                <img src="/icon/arrow_down.svg" class="arrow-collapse-custom-select" :class="{ 'open': dropdownActive }" alt="{{ $t('expand_the_list') }}" />
            </div>
        </div>
        <div v-show="dropdownActive" class="custom-options">
            <div class="input-search">
                <div class="d-flex flex-row input-container">
                    <img :src="SearchIcon" alt="search icon" />
                    <input v-model="modelSearch" type="text" class="input-search-input" placeholder="{{ $t('search_by_group') }}" />
                    <img v-show="modelSearch?.length" :src="ArrowDone" alt="clear icon" @click="modelSearch = null" />
                </div>
            </div>
            <div v-for="option in filteredSelect" :key="option.id" class="custom-option" @click="selectOption(option)">
                {{ option.value }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import SearchIcon from '@/assets/images/icons/search_gray.svg';
import ArrowDone from '@/assets/images/icons/arrow_done.svg';

const props = defineProps<{
    modelValue?: { id: string | number; value: string | number };
    label: string;
    options: { id: string | number; value: string | number }[];
}>();
const emit = defineEmits<{ (e: 'update:modelValue', value: { id: string | number; value: string | number }): void }>();
const selectedOption = ref(props.modelValue);
const dropdownActive = ref(false);
const modelSearch = ref<string | null>(null);

const toggleDropdown = () => {
    dropdownActive.value = !dropdownActive.value;
};
const selectOption = option => {
    selectedOption.value = option;
    emit('update:modelValue', option);
    dropdownActive.value = false;
};
const filteredSelect = computed(() => {
    const conditionSearch = modelSearch.value !== null && modelSearch.value.length > 2;
    return conditionSearch ? props.options.filter(({ value }) => value.toString().includes(modelSearch.value)) : props.options;
});
</script>

<style scoped lang="scss">
.input-search {
    font-family: Roboto;
    padding: 10px 16px;
    border-radius: 3px;
    border: 1px solid var(--main-grey-E4E6E7, #e4e6e7);
    background: var(--main-white-FFFFFF, #fff);
    justify-items: center;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
    }
    .input-container {
        gap: 8px;
    }
    .input-search-input {
        border: unset;
        width: 100%;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        &::placeholder {
            color: var(--grey-01-AAAAAA, #aaa);
        }
        &:focus {
            border: unset;
            outline: none;
        }
    }
}
.arrow-collapse-custom-select.open {
    transform: rotate(180deg);
}
.label-custom-select {
    color: #aaa;
    font-family: Roboto;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}
.container-title-and-option {
    gap: 4px;
}

.custom-select-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Roboto;

    .custom-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 16px;
        border-radius: 3px;
        border: 1px solid var(--main-grey-E4E6E7, #e4e6e7);
        background-color: var(--main-white-FFFFFF, #fff);
        position: relative;
        cursor: pointer;

        &-value {
            flex-grow: 1;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }

        &-icon {
            margin-left: 12px;
        }
    }

    .custom-options {
        padding: 12px;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        border: 1px solid var(--main-grey-E4E6E7, #e4e6e7);
        background-color: var(--main-white-FFFFFF, #fff);
        box-shadow: 0px 4px 16px 0px rgba(209, 209, 209, 0.25);
        z-index: 1000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        gap: 4px;

        .custom-option {
            padding: 8px 16px;
            border-radius: 3px;
            &:hover {
                color: var(--other-blue-48538B, #48538b);
                background: var(--grey-08-F7F9FB, #f7f9fb);
            }
        }
    }
}
</style>
