<template>
    <div>
        <AutoCompleet v-if="!isLargeScreen" v-model="groupSelect" class="search-on-smile" :options="selectCategory" label="$t('group_of_names')"  />
        <div class="table-container">
            <table class="custom-table">
                <thead>
                    <tr>
                        <th class="hide-sm">
                            <HeadWithSearch v-model="groupSearch" :title="$t('package.title_groups.text')" :placeholder="$t('package.search.text')" />
                        </th>
                        <th>
                            <HeadWithSearch v-model="categorySearch" :title="$t('package.cargo_name.text')" :placeholder="$t('package.search.text')" />
                        </th>
                        <th>
                            <HeadWithSearch v-model="itemSearch" :title="$t('package.package_type.text')" :placeholder="$t('package.search.text')" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <TableRow v-for="(item, index) in mergedData" :key="index" :item="item" @select:category="categorySelectFn" @select:group="groupSelectFn" />
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import type { TableData, TableObjectSecondCol, typeItemTable } from '~/@types/Upakovka';
import { useMediaQuery, watchArray } from '@vueuse/core';
import AutoCompleet from '~/components/Input/AutoCompleet.vue';
import HeadWithSearch from '~/pages/package/components/TableSearch/HeadWithSearch.vue';
import TableRow from '~/pages/package/components/TableSearch/TableRow.vue';

const props = defineProps<{ data: TableData }>();
const groupSelect = ref<typeItemTable>();
const categorySelect = ref<typeItemTable>();

const groupSearch = ref('');
const categorySearch = ref('');
const itemSearch = ref('');

const uniqueItemsIdsCat = computed(() => {
    const arrItsItems: number[] = props.data.category.reduce((acc: number[], { children }) => {
        acc.push(...children);
        return acc;
    }, []);
    return [...new Set(arrItsItems)];
});

const itemsWithCategory = computed(() => {
    return props.data.items.filter(item => uniqueItemsIdsCat.value.includes(item.id));
});

const groupsArr = ref([]);
const categoryArr = ref([]);
const itemsArr = ref([]);
const isLargeScreen = useMediaQuery('(min-width: 577px)');

watchArray(
    [groupSelect, categorySelect, groupSearch, categorySearch, itemSearch],
    () => {
        let category = [...props.data.category];
        let groups = [...props.data.groups];
        let items = [...itemsWithCategory.value];
        if (categorySelect.value) {
            groups = groups.filter(item => item.children.includes(categorySelect.value.id));
            items = items.filter(({ id }) => categorySelect.value.children.includes(id));
        }
        if (groupSelect.value) {
            category = category.filter(item => item.parent === groupSelect.value.id);
            items = items.filter(({ id }) => category.map(i => i.children.includes(id)));
        }
        const filterByIncludes = (arr, key, value) => (value ? arr.filter(i => i[key].toLowerCase().includes(value.toLowerCase())) : arr);
        groupsArr.value = filterByIncludes(groups, 'name', groupSearch.value);
        categoryArr.value = filterByIncludes(category, 'name', categorySearch.value);
        itemsArr.value = filterByIncludes(items, 'name', itemSearch.value);
    },
    {
        immediate: true,
    }
);
const selectCategory = computed((): TableObjectSecondCol[] => {
    return groupsArr.value.map((item: TableObjectSecondCol): TableObjectSecondCol => ({ value: item.name, ...item }));
});
groupSelect.value = selectCategory.value.at(0);

const mergedData = computed(() => {
    const maxLength = Math.max(groupsArr.value.length, categoryArr.value.length, itemsArr.value.length);
    const merged = [];
    for (let i = 0; i < maxLength; i++) {
        const groupElement = groupsArr.value[i] ? { ...groupsArr.value[i], selected: groupSelect.value?.id === groupsArr.value[i].id } : {};
        const categoryElement = categoryArr.value[i] ? { ...categoryArr.value[i], selected: categorySelect.value?.id === categoryArr.value[i].id } : {};
        const itemElement = itemsArr.value[i] || {};
        merged.push({ group: groupElement, category: categoryElement, item: itemElement });
    }
    return merged;
});
const categorySelectFn = item => (categorySelect.value = item);
const groupSelectFn = item => (groupSelect.value = item);
</script>

<style lang="scss" scoped>
.search-on-smile {
    margin-bottom: 20px;
}

.table-container {
    max-height: 32.125rem;
    overflow: auto;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table th {
    background-color: #f7f9fb;
    font-weight: bold;
}

.custom-table thead th {
}

.custom-table thead input {
    margin-top: 0.5rem;
    width: 100%;
    box-sizing: border-box;
}

.custom-table tbody tr:hover {
    background-color: var(--grey-12-fafafa, #d6d6d6);
}

.table-container::-webkit-scrollbar {
    width: 16px;
}

.table-container::-webkit-scrollbar-track {
    border-radius: 8px;
    background: var(--grey-08-F7F9FB, #f7f9fb);
}

.table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: var(--grey-03-E4E6E7, #e4e6e7);
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: var(--grey-08-F7F9FB, #dcdcdc);
}

@media screen and (max-width: 576px) {
    .hide-sm {
        display: none !important;
    }
    td,
    th {
        width: 50%;
    }
}
</style>
